import * as React from "react"
import Layout from "../../components/layout"

import ColumnWithIcon from "../../modules/columnWithIcon";
import Image from "../../modules/imageComponent";
import Paginator from "../../modules/paginator";

import anim1 from '../../images/animations/icon_13/animation'
import anim2 from '../../images/animations/icon_12/animation'
import anim3 from '../../images/animations/icon_10/animation'
import Lottie from "react-lottie-player";
import mainAnim from "../../images/animations/main_icon_4/animation";

const MarketmakePage = () => {
    return (
        <Layout className={'technology'} title={'Marketmake'}>
            <section>
                <div className={'container'}>

                    <div className={'row pb-0 mb-0 align-items-center'}>
                        <div className={'col col-12 col-md-4 col-lg-3 col-md-3'}>
                        </div>
                        <div className={'col col-12 col-md-8 col-lg-9'}>
                            <Paginator current={4} prev={3} next={1}/>
                        </div>
                    </div>
                    <div className={'row pb-lg-5 align-items-center'}>
                        <div className={'col col-12 col-md-4 col-lg-3 py-5 py-md-0 pe-md-5 hero-image marketmake'}>
                            <Lottie animationData={mainAnim} play loop={false}/>
                        </div>
                        <div className={'col col-12 col-md-5 align-top'}>
                            <h1>Marketmake</h1>
                            <div className={'subtitle'}>
                                <i>Note:</i> Marketmaking is presently only possible through Penumbra's Command Line, and is suggested only for advanced users
                            </div>
                            <div className={'subtitle'}>
                                <a style={{color: "#99f"}} target={'_blank'}
                                   href="https://guide.penumbra.zone/lp">Learn how</a>
                            </div>
                        </div>
                    </div>


                    <div className={'row pt-4'}>
                        <div className={'col col-12 col-md-6 col-lg-3 py-5 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim1} icon={'technology/icon2.svg'} title={'Concentrated Liquidity'} text={'ZSwap, Penumbra’s shielded AMM design, supports concentrated liquidity, allowing market allocation of liquidity over the price range of a trading pair.'}/>
                        </div>
                        <div className={'col col-12 col-md-6 col-lg-3 py-5 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim2} icon={'technology/icon1.svg'} title={'Private Strategies'} text={'Every market is also a market of information. As a fully shielded chain, Penumbra allows market makers to control the disclosure of information about their trading strategies, instead of revealing all of their activity to the world.'}/>
                        </div>
                        <div className={'col col-12 col-md-6 col-lg-3 py-5 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim3} icon={'technology/icon12.svg'} title={'No MEV'} text={'Transparent chains allow miners to extract value (MEV) by inspecting the contents of transactions and reordering or front-running them. However, as a fully shielded chain, Penumbra has no MEV because transactions do not leak data about user activity.'}/>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default MarketmakePage
